import { flowRight, isEqual, isString, omit, differenceBy, get, head, debounce } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from '../../../common/components/runtime-context';
import { Interpolate } from 'react-i18next';
import classNames from 'classnames';
import { Img } from '@wix/communities-image-lib';
import moment from 'moment';
import '../../services/moment-locales';
import { MODAL_TYPE_CREATE_COMMENT } from '../modals/comment-create-modal/comment-create-modal-type';
import { BUTTON_COLOR_PATH } from '@wix/communities-forum-client-commons/dist/src/constants/wix-params';
import { QUESTION } from '@wix/communities-forum-client-commons/dist/src/constants/post-types';
import CommentHeader from '../comment-header';
import CommentFooter from '../comment-footer';
import CommentVote from '../comment-vote';
import Reply from './index';
import RichContentViewer from '../rich-content-viewer';
import TimeAgo from '../time-ago';
import withTranslate from '@wix/communities-forum-client-commons/dist/src/hoc/with-translate';
import withFontClassName from '../../hoc/with-font-class-name';
import withSettingsColor from '../../hoc/with-settings-color';
import withDividerColor from '../../hoc/with-divider-color';
import CommentForm, { SIZE_REPLY } from '../comment-form';
import CommentEditForm from '../../containers/comment-edit-form';
import withAuth from '../../hoc/with-auth';
import withDeviceType from '../../hoc/with-device-type';
import { REDUCERS } from '../../../common/components/runtime-context/reducers';
import { scrollToComment } from '../../services/scroll-to-comment';
import { convertContentStateToContent, getPostCharCount } from '../../services/post-utils';
import { triggerIosKeyboard } from '../../services/ios-utils';
import { createInitialDraftJsContentWithMention } from '../../services/create-initial-draft-content-with-mention';
import { getAnyComment } from '../../selectors/comment-selectors';
import { getHasMoreReplies } from './utils';
import { ArrowDownIcon } from '../icons/arrow-down-icon';
import { SolvedIcon } from '../icons/solved-icon';
import getThemeForReply from '../rich-content-editor/theme-reply';
import getThemeForComment from '../rich-content-editor/theme-comment';
import ReplyMobileForm from './reply-mobile-form';
import CommentAvatar from '../comment-avatar';
import styles from './comment.scss';
import { getPost } from '../../selectors/post-selectors';
import { CREATE_COMMENT } from '../../constants/interactions';
import { REPLY } from '../../constants/form-types';
import {
  EXPERIMENT_LOCAL_DRAFT,
  EXPERIMENT_POSTPAGE_REVAMP,
} from '@wix/communities-forum-client-commons/dist/src/constants/experiments';
import Loader from '@wix/communities-forum-client-commons/dist/src/components/loader';
import withExperiment from '../../hoc/with-experiment';
import withPermissions from '../../hoc/with-permissions';

const MAX_PHOTO_HEIGHT = 600;
const PHOTO_QUALITY = 60;

const shouldShowEditDate = comment =>
  comment.editedDate &&
  moment(comment.editedDate).diff(moment(comment.createdDate), 'seconds') > 60;

const getExistingAndNewReplies = (replies, newRepliesIds, mountTime, hasMoreReplies) => {
  let existingReplies = replies ? replies.filter(r => !newRepliesIds.includes(r._id)) : [];
  let newReplies = replies ? replies.filter(r => newRepliesIds.includes(r._id)) : [];
  if (newReplies.length > 0) {
    // if there are new replies pushed via socked after newReplies (submit by viewing user) has been rendered, we need to fix ordering and render pushed reply after newReplies
    const newReplyDate = moment(newReplies[0].createdDate);
    existingReplies = existingReplies.filter(r => moment(r.createdDate).isBefore(newReplyDate));
    const repliesPushedViaSocket = replies.filter(
      r => moment(r.createdDate).isAfter(newReplyDate) && !newRepliesIds.includes(r._id),
    );
    newReplies = newReplies.concat(repliesPushedViaSocket);
    if (repliesPushedViaSocket.length > 0) {
      newReplies.sort((r1, r2) => moment(r1.createdDate).diff(moment(r2.createdDate)));
    }
  } else if (hasMoreReplies) {
    // if new reply is pushed via socket, but user doesn't see all replies (he has to load more), then we cannot show this new reply
    existingReplies = existingReplies.filter(r => moment(r.createdDate).isBefore(mountTime));
  }

  return { existingReplies, newReplies };
};

class Comment extends Component {
  state = {
    isCommentFormVisible: false,
    newReplies: [],
    replyUser: null,
    mountTime: moment(),
    hasError: false,
    isLoadingReplies: false,
  };

  componentDidCatch(error) {
    console.error(error);
    this.setState({ hasError: true });
  }

  shouldComponentUpdate(nextProps, nextState) {
    return (
      !isEqual(omit(this.props.comment, 'content'), omit(nextProps.comment, 'content')) ||
      this.props.editedCommentId !== nextProps.editedCommentId ||
      this.props.hasMoreReplies !== nextProps.hasMoreReplies ||
      this.props.bestAnswerCommentId !== nextProps.bestAnswerCommentId ||
      this.props.isBestAnswer !== nextProps.isBestAnswer ||
      this.props.isLastReply !== nextProps.isLastReply ||
      this.props.isLastComment !== nextProps.isLastComment ||
      this.props.dividerColor !== nextProps.dividerColor ||
      this.props.bestAnswerColor !== nextProps.bestAnswerColor ||
      this.props.replies.length !== nextProps.replies.length ||
      this.props.isCommentsDisabled !== nextProps.isCommentsDisabled ||
      this.props.nextRepliesCount !== nextProps.nextRepliesCount ||
      this.state.isCommentFormVisible !== nextState.isCommentFormVisible ||
      this.state.newReplies.length !== nextState.newReplies.length ||
      this.state.isLoadingReplies !== nextState.isLoadingReplies ||
      this.state.isMarkAsBestAnswerButtonVisible !== nextState.isMarkAsBestAnswerButtonVisible ||
      get(this.state.replyUser, 'id') !== get(nextState.replyUser, 'id')
    );
  }

  componentDidUpdate(prevProps) {
    const {
      isPostPageRevampEnabled,
      isMobile,
      parentCommentId,
      hasMoreReplies,
      replies,
      nextRepliesCount,
      currentUser,
    } = this.props;

    if (isPostPageRevampEnabled && isMobile && !parentCommentId) {
      const newReply = head(differenceBy(replies, prevProps.replies, '_id'));
      const isSameOwner = newReply && newReply.ownerSId === currentUser;
      if (
        newReply &&
        hasMoreReplies &&
        nextRepliesCount > prevProps.nextRepliesCount &&
        isSameOwner
      ) {
        this.setState({
          newReplies: this.state.newReplies.concat(newReply._id),
        });
        scrollToComment(newReply._id, { isReply: true, shouldPinDown: false });
      }
      if (
        newReply &&
        !hasMoreReplies &&
        !prevProps.hasMoreReplies &&
        replies.length > prevProps.replies.length &&
        isSameOwner
      ) {
        scrollToComment(newReply._id, { isReply: true, shouldPinDown: false });
      }
    }
  }

  componentWillUnmount() {
    this.handleCommentChange.cancel();
  }

  handleCommentChange = debounce(
    () => this.props.emitTypingThrottled(this.props.comment.postId, this.props.currentUser),
    800,
    { maxWait: 3000, leading: true },
  );

  renderImage() {
    const {
      comment: { image },
    } = this.props;

    if (!image) {
      return null;
    }

    return (
      <div>
        <Img
          className={styles.image}
          src={image}
          height={MAX_PHOTO_HEIGHT}
          quality={PHOTO_QUALITY}
        />
      </div>
    );
  }

  setComment = ref => {
    this.ref = ref;
  };

  renderBody() {
    const { comment, parentCommentId } = this.props;
    const isReply = isString(parentCommentId);

    return (
      <div>
        <RichContentViewer
          key={comment.editedDate}
          initialRawState={comment.content}
          themeGetter={isReply ? getThemeForReply : getThemeForComment}
          readOnly
          compact
          origin="comment"
        />
      </div>
    );
  }

  renderEditDate() {
    const { comment } = this.props;

    if (!comment.editedDate) {
      return null;
    }

    return (
      <p className={styles.editDate} data-hook="edit-date">
        <Interpolate i18nKey="comment.edited" timeAgo={<TimeAgo time={comment.editedDate} />} />
      </p>
    );
  }

  showReplyForm = (replyUser = null) => {
    this.props.reportReplyIntent();
    this.setState({ isCommentFormVisible: true, replyUser });
  };

  hideReplyForm = () => this.setState({ isCommentFormVisible: false });

  onReplyClick = () => {
    const {
      isMobile,
      openModal,
      comment,
      reportReplyIntent,
      isPostPageRevampEnabled,
      onReplyClick,
      parentCommentId,
    } = this.props;
    if (isMobile && isPostPageRevampEnabled) {
      reportReplyIntent();
      openModal(MODAL_TYPE_CREATE_COMMENT, {
        postId: comment.postId,
        comment: comment,
        timestamp: Date.now(),
      });
      triggerIosKeyboard();
    } else {
      const isReply = isString(parentCommentId);
      const replyUserDetails = comment.owner
        ? { name: comment.owner.name, slug: comment.owner.slug, id: comment.owner.siteMemberId }
        : null;
      return isReply ? onReplyClick(replyUserDetails) : this.showReplyForm();
    }
  };

  getInitialFormValues = (values = {}) => {
    if (!this.props.isAuthenticated) return { ...values };
    return {
      ...values,
    };
  };

  handleCommentFormSubmit = ({ values: newComment }, fastForm) => {
    const {
      comment,
      parentCommentId,
      createCommentReplyPromisified,
      interactionStarted,
      userEventsClickPublish,
    } = this.props;
    userEventsClickPublish({
      type: 'reply',
      symbolCount: getPostCharCount(newComment),
      postId: comment.postId,
      parentId: parentCommentId || comment._id,
    });
    interactionStarted(CREATE_COMMENT);
    return createCommentReplyPromisified(
      comment.postId,
      parentCommentId || comment._id,
      convertContentStateToContent(newComment),
    )
      .then(comment => {
        this.setState({
          isCommentFormVisible: false,
          newReplies: this.state.newReplies.concat(comment._id),
        });
      })
      .catch(() => {
        fastForm.stopSubmit();
      });
  };

  getFormIdentifier = () => this.props.identifier || `comment-${this.props.comment._id}-reply`;

  loadMoreReplies = () => {
    this.setState({ isLoadingReplies: true });
    this.props
      .fetchRepliesPromisified({
        commentId: this.props.comment._id,
        page: this.props.nextRepliesPage,
        postId: this.props.comment.postId,
        postType: this.props.postType,
      })
      .then(() => this.setState({ isLoadingReplies: false }));
  };

  renderReplies = replies => {
    const {
      comment,
      isMobile,
      bestAnswerCommentId,
      onLikeClick,
      editedCommentId,
      isQuestion,
      postType,
      isPostPageRevampEnabled,
      isLastComment,
      hasMoreReplies,
    } = this.props;
    return replies.map((r, index) => {
      const isLastReply = index === replies.length - 1;
      return (
        <div key={r._id}>
          {r._id === editedCommentId ? (
            <div
              className={classNames(
                isQuestion ? styles.replyAnswerEditWrapper : styles.replyEditWrapper,
              )}
            >
              <CommentEditForm size={SIZE_REPLY} comment={r} postType={postType} />
            </div>
          ) : (
            <Reply
              comment={r}
              isLastReply={isLastReply}
              parentCommentId={comment._id}
              identifier={this.getFormIdentifier()}
              isBestAnswer={bestAnswerCommentId === r._id}
              onLikeClick={onLikeClick}
              onReplyClick={this.showReplyForm}
            />
          )}
          {isMobile && !isLastReply && !isPostPageRevampEnabled && this.renderReplyDivider()}
          {isMobile && isPostPageRevampEnabled
            ? hasMoreReplies && isLastReply
              ? null
              : isLastReply && isLastComment
              ? null
              : this.renderReplyDivider()
            : null}
        </div>
      );
    });
  };

  renderReplyDivider = () => {
    const { isQuestion } = this.props;
    return (
      <div
        className={classNames(styles.replyDivider, { [styles.replyDividerQuestion]: isQuestion })}
        data-hook={isQuestion.toString()}
        style={{
          borderColor: this.props.dividerColor,
        }}
      />
    );
  };

  renderLoadMoreReplies = existingReplies => {
    const { isPostPageRevampEnabled, t, isMobile, nextRepliesCount } = this.props;
    const { newReplies } = this.state;
    const containerClass = classNames(
      styles.loadRepliesContainer,
      isPostPageRevampEnabled && isMobile && styles.postPageRevamp,
      'forum-text-color',
    );
    const replyCount = nextRepliesCount - newReplies.length;
    const renderNewLoader = () => (
      <div className={classNames(styles.loadReplies, 'button-color')}>
        <span>{t('post-page.view-more-replies', { count: replyCount })}</span>
        <ArrowDownIcon className={classNames('button-fill', styles.loadMoreIcon)} />
      </div>
    );

    return (
      <div onClick={this.loadMoreReplies} className={containerClass} data-hook="load-more-replies">
        {this.state.isLoadingReplies ? (
          <Loader />
        ) : isPostPageRevampEnabled && isMobile ? (
          renderNewLoader()
        ) : (
          t('comment.load-replies')
        )}
      </div>
    );
  };

  renderCommentForm = () => {
    const {
      currentUser,
      isMobile,
      dividerColor,
      isAuthenticated,
      isQuestion,
      comment,
      postType,
      isLocalDraftEnabled,
      isPostPageRevampEnabled,
    } = this.props;

    if (isMobile && isPostPageRevampEnabled) return null;

    const { replyUser } = this.state;
    let formInitialValues;

    if (!isAuthenticated) {
      formInitialValues = {};
    } else if (replyUser && replyUser.id !== currentUser.siteMemberId) {
      formInitialValues = { content: createInitialDraftJsContentWithMention(replyUser) };
    }

    return isMobile ? (
      <ReplyMobileForm
        key={get(replyUser, 'id')}
        formInitialValues={this.getInitialFormValues(formInitialValues)}
        onSubmit={this.handleCommentFormSubmit}
        onChange={this.handleCommentChange}
        formIdentifier={this.getFormIdentifier()}
        dividerColor={this.props.dividerColor}
        postId={comment.postId}
        parentCommentId={comment._id}
        isLocalDraftEnabled={isLocalDraftEnabled}
      />
    ) : (
      <div
        className={classNames(styles.replyFormContainer, {
          [styles.replyAnswerFormContainer]: isQuestion,
        })}
        style={{ borderColor: dividerColor }}
        data-hook="reply-desktop-form"
      >
        <CommentForm
          key={get(replyUser, 'id')}
          contentClassName={styles.replyForm}
          formInitialValues={this.getInitialFormValues(formInitialValues)}
          onSubmit={this.handleCommentFormSubmit}
          onChange={this.handleCommentChange}
          formName={this.getFormIdentifier()}
          size={SIZE_REPLY}
          autoFocus
          scrollIntoView
          resetFormOnCancel
          alwaysShowCancelButton
          onCancel={this.hideReplyForm}
          postId={comment.postId}
          postType={postType}
          parentCommentId={comment._id}
          type={REPLY}
          isLocalDraftEnabled={isLocalDraftEnabled}
        />
      </div>
    );
  };

  showMarkAsBestAnswer = () => this.setState({ isMarkAsBestAnswerButtonVisible: true });
  hideMarkAsBestAnswer = () => this.setState({ isMarkAsBestAnswerButtonVisible: false });

  render() {
    if (this.state.hasError) {
      return null;
    }

    const {
      comment,
      contentFontClassName,
      isBestAnswer,
      bestAnswerColor,
      dividerColor,
      replies,
      onLikeClick,
      parentCommentId,
      isLastReply,
      isLastComment,
      hasMoreReplies,
      isMobile,
      editedCommentId,
      isCommentsDisabled,
      isQuestion,
      postType,
      post,
      can,
      isPostPageRevampEnabled,
      t,
    } = this.props;
    const isReply = isString(parentCommentId);
    const { existingReplies, newReplies } = getExistingAndNewReplies(
      replies,
      this.state.newReplies,
      this.state.mountTime,
      hasMoreReplies,
    );
    const hasNewReplies = newReplies.length > 0;
    const isCommentWithReplies = replies.length > 0;
    const isCommentInEdit = comment._id === editedCommentId;
    const shouldShowEdited = shouldShowEditDate(comment);
    const shouldShowQuickMarkAsBestAnswer =
      can('add-best-answer-comment', 'post', post) && isQuestion && !isReply;

    const commentClass = classNames(
      styles.comment,
      contentFontClassName,
      'forum-text-color',
      'forum-card-border-color',
      'comment',
      isReply && (isQuestion ? styles.answerIndent : styles.replyIndent),
      !isReply && isQuestion && styles.answer,
    );
    const contentWrapperClass = classNames(styles.contentWrapper, {
      [styles.reply]: isReply,
      [styles.isLastReply]: isLastReply,
      [styles.withReplies]: isCommentWithReplies,
      [styles.withCommentForm]: this.state.isCommentFormVisible,
    });
    const contentStyle = { borderColor: dividerColor };

    const renderCommentStats = () => {
      const classes = classNames(styles.editDate);
      if (isMobile) {
        return shouldShowEdited ? (
          <p className={classes}>
            <Interpolate i18nKey="comment.edited" timeAgo={<TimeAgo time={comment.editedDate} />} />
          </p>
        ) : (
          <TimeAgo time={comment.createdDate} className={classes} />
        );
      }
      return shouldShowEdited && isMobile && this.renderEditDate();
    };
    return (
      <div
        className={classNames(styles.commentWrapper, {
          [styles.inEdit]: isCommentInEdit,
          [styles.reply]: isReply,
        })}
        data-hook="comment"
      >
        {isCommentInEdit ? (
          <div className={classNames(styles.commentInnerWrapper)}>
            {isQuestion && <div className={styles.answerEditIndent} />}
            <div
              className={classNames(styles.commentFormWrapper, {
                [styles.withBottomBorder]: !isCommentWithReplies,
              })}
              style={{ borderColor: dividerColor }}
            >
              <CommentEditForm comment={comment} postType={postType} />
            </div>
          </div>
        ) : (
          <article
            id={comment._id}
            ref={this.setComment}
            className={commentClass}
            data-hook="comment"
            tabIndex="-1"
            style={{ backgroundColor: isBestAnswer ? bestAnswerColor : '' }}
            onMouseEnter={shouldShowQuickMarkAsBestAnswer && this.showMarkAsBestAnswer}
            onMouseLeave={shouldShowQuickMarkAsBestAnswer && this.hideMarkAsBestAnswer}
          >
            {(isPostPageRevampEnabled
              ? !isMobile && !isReply && isQuestion
              : !isReply && isQuestion) && <CommentVote comment={comment} />}
            <div className={contentWrapperClass} style={contentStyle}>
              <div className={styles.header}>
                <CommentHeader
                  type={CommentAvatar.REPLY}
                  comment={comment}
                  showMoreButton
                  showBestAnswer={
                    isPostPageRevampEnabled ? !isMobile && isBestAnswer : isBestAnswer
                  }
                  showMoreButtonBestAnswer={isQuestion && !isReply}
                  showEditedDate={shouldShowEdited && !isMobile}
                />
              </div>
              {!isPostPageRevampEnabled && renderCommentStats()}
              <div className={classNames(styles.content, 'comment__content')}>
                {isPostPageRevampEnabled && isMobile && isBestAnswer && (
                  <div className={styles.bestAnswer} data-hook="best-answer">
                    <SolvedIcon className={classNames(styles.bestAnswerIcon, 'button-fill')} />
                    <span className={'button-color'}>{t('comment.solved.best')}</span>
                  </div>
                )}
                {this.renderBody()}
                {this.renderImage()}
              </div>
              <CommentFooter
                comment={comment}
                onLikeClick={onLikeClick}
                onReplyClick={this.onReplyClick}
                showReplyLink={!isCommentsDisabled}
                showLikeButton={!isQuestion || isReply}
                showMarkAsBestAnswerButton={
                  !isBestAnswer && this.state.isMarkAsBestAnswerButtonVisible
                }
                showVoteButtons={isPostPageRevampEnabled && isMobile && isQuestion && !isReply}
              />
            </div>
          </article>
        )}
        <div>
          {isMobile && isPostPageRevampEnabled && !isReply && (
            <div
              className={styles.commentWithReplyMobileDivider}
              style={{ borderColor: dividerColor }}
            />
          )}
          {isMobile
            ? isPostPageRevampEnabled
              ? null
              : existingReplies.length > 0 && this.renderReplyDivider()
            : existingReplies.length > 0 && this.renderReplyDivider()}
          {this.renderReplies(existingReplies)}
          {hasMoreReplies && this.renderReplyDivider()}
          {hasMoreReplies && this.renderLoadMoreReplies(existingReplies)}
          {isPostPageRevampEnabled && hasMoreReplies && isMobile && !isLastReply && !isLastComment && (
            <div
              className={classNames(styles.commentWithReplyMobileDivider, {
                [styles.withNewReplies]:
                  isMobile && isPostPageRevampEnabled && hasNewReplies && hasMoreReplies,
              })}
              style={{ borderColor: dividerColor }}
            />
          )}
          {hasNewReplies && hasMoreReplies && this.renderReplyDivider()}
          {hasNewReplies && this.renderReplies(newReplies)}
          {hasNewReplies && isMobile && isPostPageRevampEnabled && this.renderReplyDivider()}
          {this.state.isCommentFormVisible && this.renderCommentForm()}
        </div>
        {isMobile && !isReply && !isPostPageRevampEnabled && (
          <div
            className={styles.commentWithReplyMobileDivider}
            style={{ borderColor: dividerColor }}
          />
        )}
        {!isMobile && (isCommentWithReplies || this.state.isCommentFormVisible) && (
          <div
            className={classNames(styles.commentWithReplyDividerWrapper, {
              [styles.isQuestion]: isQuestion,
            })}
          >
            <div className={styles.commentWithReplyDivider} style={{ borderColor: dividerColor }} />
          </div>
        )}
      </div>
    );
  }
}

Comment.propTypes = {
  comment: PropTypes.object.isRequired,
  t: PropTypes.func,
  onLikeClick: PropTypes.func.isRequired,
  contentFontClassName: PropTypes.string.isRequired,
  isBestAnswer: PropTypes.bool,
  isLastReply: PropTypes.bool,
  isLastComment: PropTypes.bool,
  hasMoreReplies: PropTypes.bool,
  isMobile: PropTypes.bool,
  dividerColor: PropTypes.string,
  bestAnswerCommentId: PropTypes.string,
  replies: PropTypes.array,
  currentUser: PropTypes.object,
  createCommentReplyPromisified: PropTypes.func,
  fetchReplies: PropTypes.func,
  fetchRepliesPromisified: PropTypes.func,
  emitTypingThrottled: PropTypes.func,
  identifier: PropTypes.string,
  parentCommentId: PropTypes.string,
  editedCommentId: PropTypes.string,
  nextRepliesPage: PropTypes.number,
  isCommentsDisabled: PropTypes.bool,
  isQuestion: PropTypes.bool,
  postType: PropTypes.string,
  reportReplyIntent: PropTypes.func.isRequired,
  userEventsClickPublish: PropTypes.func.isRequired,
  openModal: PropTypes.func.isRequired,
  nextRepliesCount: PropTypes.string,
};

const mapRuntimeToProps = (state, ownProps, actions) => {
  const { hasMoreReplies, nextRepliesPage, nextRepliesCount, replies } = getHasMoreReplies(
    state,
    ownProps,
  );
  const post = getPost(state, ownProps.comment.postId);

  return {
    replies,
    hasMoreReplies,
    nextRepliesPage,
    nextRepliesCount,
    isCommentsDisabled: get(post, 'isCommentsDisabled', false),
    comment: isString(ownProps.parentCommentId)
      ? getAnyComment(state, ownProps.comment._id, ownProps.comment.postId)
      : ownProps.comment,
    createCommentReplyPromisified: actions.createCommentReplyPromisified,
    fetchReplies: actions.fetchReplies,
    fetchRepliesPromisified: actions.fetchRepliesPromisified,
    emitTypingThrottled: actions.emitTypingThrottled,
    interactionStarted: actions.interactionStarted,
    isQuestion: post.postType === QUESTION,
    postType: post.postType,
    post: post,
    reportReplyIntent: () =>
      actions.userEventsReplyOrCommentIntent({ type: 'reply', origin: 'button' }),
    userEventsClickPublish: actions.userEventsClickPublish,
    openModal: actions.openModal,
  };
};

export default flowRight(
  withFontClassName,
  withTranslate,
  withSettingsColor({
    path: BUTTON_COLOR_PATH,
    propName: 'bestAnswerColor',
    alpha: 0.05,
    siteColorAlpha: 0.1,
    siteColorFallback: 'color-8',
    fallbackColor: '#27a456',
  }),
  withDividerColor,
  withAuth,
  withPermissions,
  withDeviceType,
  withExperiment({
    isLocalDraftEnabled: EXPERIMENT_LOCAL_DRAFT,
    isPostPageRevampEnabled: EXPERIMENT_POSTPAGE_REVAMP,
  }),
  connect(mapRuntimeToProps, [REDUCERS.POSTS, REDUCERS.COMMENTS]),
)(Comment);
